import React from "react";
import "../styles/Tile.css";

function Statement() {
	return (
		<div className="tile">
			<h1>
				THE WORLD WILL WEAR THE CROWN <br /><br />
				<div className="emoji-stack">
					<span className="crown">👑</span><br />
					<span className="world">🌎</span>
				</div>
			</h1>
			<span className="statement-text">
				Ancient scriptures state that KING symbolizes power, wealth, and authority. It has since
				evolved into something even greater. Being a KING is about mastery, dominance and success.
				<br />
				<br />
				KING is deeply rooted in modern day culture through sport, music, and media. $KING doesn’t
				need to create culture, it is culture.
				<br />
				<br />
				Join us $KING
			</span>
		</div>
	);
}

export default Statement;
