import React from 'react';
import '../styles/Tile.css';

function HowToBuy() {
  return (
    <div className="tile">
      <h1>HOW TO BUY</h1>
      <ul className="list-bubbles how-to-buy-bubbles">
      <li>
          <img src="./images/prerich.png" alt="prerich" className="step-number" />
          GO TO <a href="https://app.prerich.com/ref/KING" target="_blank" rel="noopener noreferrer" className="link">
          PRERICH{" "}
          </a>
          AND BUY $KING WITH APPLE/GOOGLE/CARD
        </li>
        <span className="or-text">OR</span>
        <li>
          <img src="./images/one.png" alt="Step 1" className="step-number" />
          CREATE A SOLANA WALLET WITH <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer" className="link">
          PHANTOM
          </a>
          <img src="./images/assets/phantom.png" alt="Phantom Logo" className="inline-icon" id="phantom" />
        </li>
        <li>
          <img src="./images/two.png" alt="Step 2" className="step-number" />
          LOAD UP ON SOL
          <img src="./images/assets/sol.png" alt="Phantom Logo" className="inline-icon" id="sol"/>
        </li>
        <li>
          <img src="./images/three.png" alt="Step 3" className="step-number" />
          SWAP SOL FOR $KING <a href="https://raydium.io/swap/?inputMint=sol&outputMint=HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E" target="_blank" rel="noopener noreferrer" className="link">HERE 👑</a>
        </li>
        <li className="step-four">
          <div className="step-text">WELCOME TO THE RANKS KING</div>
          <img src="./images/assets/take_this.png" alt="you dropped this" className="king-image" />
        </li>
      </ul>
    </div>
  );
}

export default HowToBuy;
