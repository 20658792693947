import React, { useContext } from "react";
import "../styles/Ticker.css";
import { PriceHolderContext } from './PriceHolderContext';

const Ticker = () => {
    const { priceInfo } = useContext(PriceHolderContext); 
    const tickerContent = `KING/USD $${priceInfo.usd} |
                          KING/SOL ${priceInfo.native} |
                          MARKET CAP ${priceInfo.marketCap} |
                          LIQUIDITY ${priceInfo.liquidity} |
                          24H VOLUME ${priceInfo.volume24H} |
                          HOLDERS ${priceInfo.holders} |`;

    const contentArray = new Array(50).fill(tickerContent);

    return (
        <div className="ticker">
            <div className="ticker-wrap">
                <div className="ticker-move">
                    {contentArray.map((content, index) => (
                        <div className="ticker-item" key={index}>{content}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Ticker;
