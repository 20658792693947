import React from 'react';
import '../styles/Tile.css';

function Tokenomics() {
  return (
    <div className="tile">
      <h1>TOKENOMICS</h1>
      <ul className='list-bubbles'>
        <li>1,000,000,000,000 SUPPLY</li>
        <li>80% Liquidity Pool</li>
        <li>20% Liquidity Providers</li>
        <li>100% LP BURNED 🔥</li>
      </ul>
    </div>
  );
}

export default Tokenomics;
