import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>$KING is a meme coin with no intrinsic value or expectation of financial return.</p>
      <p>There is no formal team or roadmap. The coin is completely useless and for entertainment purposes only.</p>
      <ul className="social-links">
        <li>
          <a href="https://dexscreener.com/solana/3cev8cqffbvkr2gqtzjzj5uth8slzuwsgxgz1cr9peja" target="_blank" rel="noopener noreferrer">
            <img src="./images/dexscreener.png" alt="Dexscreener" className="footer-icon" />
          </a>
        </li>
        <li>
          <a href="https://www.dextools.io/app/en/solana/pair-explorer/3Cev8cQffBvkr2gQtzjzj5uth8sLZuWsGxGz1CR9Peja?t=1724819874354" target="_blank" rel="noopener noreferrer">
            <img src="./images/dextools.png" alt="DexTools" className="footer-icon footer-icon-dextools" />
          </a>
        </li>
        <li>
          <a href="https://x.com/KingSOLMeme" target="_blank" rel="noopener noreferrer">
            <img src="./images/x.png" alt="X" className="footer-icon" />
          </a>
        </li>
        <li>
          <a href="https://www.coingecko.com/en/coins/king" target="_blank" rel="noopener noreferrer">
            <img src="./images/coingecko.png" alt="CoingGecko" className="footer-icon footer-icon-coingecko" />
          </a>
        </li>
        <li>
          <a href="https://holderscan.com/token/HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E" target="_blank" rel="noopener noreferrer">
            <img src="./images/holderscan.png" alt="HolderScan" className="footer-icon footer-icon-holderscan" />
          </a>
        </li>
        <li>
          <a href="https://t.co/gviCp5g2Kw" target="_blank" rel="noopener noreferrer">
            <img src="./images/tg.png" alt="Telegram" className="footer-icon footer-icon-telegram" />
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
