import React, { useContext, useState } from "react";
import { PriceHolderContext } from "./PriceHolderContext";
import "../styles/Dex.css";
import "../styles/PriceHolderContext.css";

function Dex() {
	const { priceInfo, convertSolToKing } = useContext(PriceHolderContext);
	const [solAmount, setSolAmount] = useState("");
	const [convertedSolAmount, setConvertedSolAmount] = useState(null); // For displaying the SOL after conversion
	const [kingAmount, setKingAmount] = useState(null);

	const handleConversion = () => {
		const result = convertSolToKing(solAmount);
		setKingAmount(result);
		setConvertedSolAmount(solAmount);
	};

	const copyToClipboard = () => {
		const textToCopy = "HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E";
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				alert(`${textToCopy} copied to clipboard!`);
			})
			.catch((err) => {
				console.error("Failed to copy text: ", err);
			});
	};

	return (
		<div className="dex-container">
			{/* Contract address displayed on mobile */}
			<p className="ca-text-dex" onClick={copyToClipboard}>
				<span>$KING HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E</span>
			</p>
			<div className="price-holder-info-container">
			<p className="ca-text" onClick={copyToClipboard}>
				<span>$KING HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E</span>
			</p>
				<section className="price-holder-tile">
					<ul className="price-holder-list-bubbles">
						<li className="price-holder-list-item">KING/USD: ${priceInfo.usd}</li>
						<li className="price-holder-list-item">KING/SOL: {priceInfo.native}</li>
						<li className="price-holder-list-item">Market Cap: {priceInfo.marketCap}</li>
						<li className="price-holder-list-item">Liquidity: {priceInfo.liquidity}</li>
						<li className="price-holder-list-item">24H Volume: {priceInfo.volume24H}</li>
						<li className="price-holder-list-item">Holders: {priceInfo.holders}</li>

						<input
							className="price-holder-list-item"
							type="number"
							value={solAmount}
							onChange={(e) => setSolAmount(e.target.value)}
							placeholder="Enter SOL amount"
						/>

						<button className="price-holder-list-item" onClick={handleConversion}>
							Estimate
						</button>
						{kingAmount && (
							<p className="price-holder-converted">
								{convertedSolAmount} SOL ≈ {kingAmount} $KING
							</p>
						)}
					</ul>
				</section>
			</div>
			<div className="dex-row">
				<a
					href="https://dexscreener.com/solana/3cev8cqffbvkr2gqtzjzj5uth8slzuwsgxgz1cr9peja"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/dexscreener.png" alt="Dexscreener" className="icon" />
					Dexscreener
				</a>
				<a
					href="https://www.dextools.io/app/en/solana/pair-explorer/3Cev8cQffBvkr2gQtzjzj5uth8sLZuWsGxGz1CR9Peja?t=1724819874354"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/dextools.png" alt="DexTools" className="icon" />
					DexTools
				</a>
			</div>
			<div className="dex-row">
				<a
					href="https://www.coingecko.com/en/coins/king"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/coingecko.png" alt="CoinGecko" className="icon" />
					CoinGecko
				</a>
				<a
					href="https://holderscan.com/token/HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/holderscan.png" alt="HolderScan" className="icon" />
					HolderScan
				</a>
			</div>
			<div className="dex-row">
				<a
					href="https://x.com/KingSOLMeme"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/x.png" alt="X" className="icon" />
				</a>
				<a
					href="https://t.co/gviCp5g2Kw"
					target="_blank"
					rel="noopener noreferrer"
					className="dex-button"
				>
					<img src="./images/tg.png" alt="Telegram" className="icon" />
					Telegram
				</a>
			</div>
		</div>
	);
}

export default Dex;
