import React, { createContext, useState, useEffect } from 'react';

export const PriceHolderContext = createContext();

export const PriceHolderProvider = ({ children }) => {
    const [priceInfo, setPriceInfo] = useState({
        usd: "Loading...",
        native: "Loading...",
        liquidity: "Loading...",
        marketCap: "Loading...",
        volume24H: "Loading...",
        holders: "Loading...",
    });

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await fetch(
                    "https://api.dexscreener.com/latest/dex/tokens/HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E"
                );
                const data = await response.json();
                const firstPair = data.pairs[0];
                const usdPrice = firstPair.priceUsd;
                const nativePrice = firstPair.priceNative;
                const currentLiquidity = firstPair.liquidity.usd;
                const currentMarketCap = firstPair.fdv;
                const volume24H = firstPair.volume.h24;
                const formattedLiquidity = parseFloat(currentLiquidity).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                const formattedMarketCap = parseFloat(currentMarketCap).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                const formattedVolume24H = parseFloat(volume24H).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                setPriceInfo((prevInfo) => ({
                    ...prevInfo,
                    usd: usdPrice,
                    native: nativePrice,
                    liquidity: formattedLiquidity,
                    marketCap: formattedMarketCap,
                    volume24H: formattedVolume24H,
                }));
            } catch (error) {
                console.error("Error fetching price:", error);
                setPriceInfo((prevInfo) => ({
                    ...prevInfo,
                    usd: "N/A",
                    native: "N/A",
                    liquidity: "N/A",
                    marketCap: "N/A",
                    volume24H: "N/A",
                }));
            }
        };

        const fetchHolders = async () => {
            try {
                const response = await fetch("https://salty-forest-28032-156d61122ac6.herokuapp.com/holders");
                const data = await response.json();
                setPriceInfo((prevInfo) => ({
                    ...prevInfo,
                    holders: data.holders.toLocaleString('en-US'),
                }));
            } catch (error) {
                console.error("Error fetching holders:", error);
                setPriceInfo((prevInfo) => ({
                    ...prevInfo,
                    holders: "N/A",
                }));
            }
        };

        fetchPrice();
        fetchHolders();
    }, []);

    // Conversion function to calculate KING tokens from SOL amount
    const convertSolToKing = (solAmount) => {
        const nativePrice = parseFloat(priceInfo.native);
        if (!nativePrice || isNaN(nativePrice)) {
            return "N/A";
        }
        const kingAmount = solAmount / nativePrice;
        return kingAmount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    return (
        <PriceHolderContext.Provider value={{ priceInfo, convertSolToKing }}>
            {children}
        </PriceHolderContext.Provider>
    );
};
