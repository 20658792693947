import React, { useState } from "react";
import { Link } from "react-router-dom";
import Ticker from "./Ticker";
import "../styles/Header.css";

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const handleLinkClick = () => {
		setIsOpen(false);
	};

	const copyToClipboard = () => {
		const textToCopy = "HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E";
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				alert(`${textToCopy} copied to clipboard!`);
			})
			.catch((err) => {
				console.error("Failed to copy text: ", err);
			});
	};

	return (
		<header className="header">
			<Ticker />
			<div className="horizontal-box">
				<img src="./images/crown.gif" alt="Crown GIF" className="crown-gif" />

				{/* <div>
					<p className="ca-text" onClick={copyToClipboard}>
						<span>HV2tLSVHRk3Yx76GsR6dGRTsebbkJJyY4ujhLAyf9T7E</span>
					</p>
				</div> */}
				<div className="menu-toggle" onClick={toggleMenu}>
					☰
				</div>
			</div>
			<div className="banner-container">
				<img src="./images/banner.png" alt="Desktop Banner" className="banner-img" />
			</div>
			<nav className={`nav-links ${isOpen ? "open" : ""}`}>
				<ul>
					<li>
						<Link to="/home" onClick={handleLinkClick}>
							HOME
						</Link>
					</li>
					<li>
						<Link to="/links" onClick={handleLinkClick}>
							LINKS
						</Link>
					</li>
					<li>
						<Link to="/howtobuy" onClick={handleLinkClick}>
							HOW TO BUY
						</Link>
					</li>
					<li>
						<Link to="/tokenomics" onClick={handleLinkClick}>
							TOKENOMICS
						</Link>
					</li>
					<li>
						<Link to="/statement" onClick={handleLinkClick}>
							ABOUT
						</Link>
					</li>
					<li>
						<Link to="/memetool" onClick={handleLinkClick}>
							MEME TOOL
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}

export default Header;
